import React from "react";
import { useConstancia } from "../../../../../../context/ConstanciaContext";
import { useFormatDate } from "../../../../../../hooks/useFormatDate";

export const RegistrationDate = () => {
  const { constancia } = useConstancia();
  const { formatDate } = useFormatDate();

  return (
    <div>
      <div>
        Fecha de alta:
        {`  ${formatDate(
          constancia.personaReturn?.datosMonotributo?.actividadMonotributista
            .periodo
        )} `}
      </div>
    </div>
  );
};
