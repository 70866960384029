import styled from "styled-components";

export const Container = styled.div`
  .contenedor-centrado {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .title {
    color: #2e363a;
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0em;
  }

  .border {
    border: 1px solid #d9d9d9; /* Color gris */
    border-radius: 10px; /* Puntas redondas, ajusta según sea necesario */
    padding: 10px;
    width: auto;
  }

  .pxx-5 {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Dos columnas con igual ancho */
    gap: 20px; /* Espacio entre las cajas */
  }

  .monotributo-radio {
    margin-top: 10px; /* Espaciado superior */
  }

  .radio-group {
    display: flex;
    gap: 10px; /* Espaciado entre radios y etiquetas */
  }

  .letra {
    color: #0ab2e6;
    font-size: 34px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: center;
  }

  .border2 {
    border: 1px solid #0ab2e6;
    border-radius: 12px; /* Puntas redondas, ajusta según sea necesario */
    padding: 8px;
    padding-top: 9px;
    padding-bottom: 9px;
    width: 50px;
    color: #0ab2e6;
  }

  .bg-azul {
    background: #007bff;
    /* background: #0055BA; */
  }

  .texto-presstaciones {
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
  }

  .bg-blanco {
    border: 1px solid #3f3f3f; /* Color gris */
    border-radius: 10px; /* Puntas redondas, ajusta según sea necesario */
    width: auto;
  }

  .numero {
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
  }

  .button {
    background-color: #0ab2e6; /* Fondo celeste */
    color: white; /* Letra blanca */
    border: none;
    padding: 10px 200px; /* Ajusta el relleno según tus preferencias */
    border-radius: 20px; /* Hace que el botón sea redondo */
    cursor: pointer;
    outline: none;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px; /* Ajusta el tamaño de la letra según tus preferencias */
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: center;
  }
  .button-red {
    background-color: #2e363a; /* Fondo warning */
    color: white; /* Letra blanca */
    border: none;
    padding: 10px 200px; /* Ajusta el relleno según tus preferencias */
    border-radius: 20px; /* Hace que el botón sea redondo */
    cursor: pointer;
    outline: none;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px; /* Ajusta el tamaño de la letra según tus preferencias */
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: center;
  }

  .banner-not-found {
    /* background-color: #2e363a; */
    /* color: white; */
    border: none;
    padding: 10px 20px;
    /* border-radius: 20px; */
    font-size: 15px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: center;
  }
`;
