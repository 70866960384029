import { useConstancia } from "../../../../context/ConstanciaContext";
import { useFormatDate } from "../../../../hooks/useFormatDate";

export const ActivityData = () => {
  const { constancia } = useConstancia();
  const { formatDate } = useFormatDate();

  return (
    <div className="container mb-2">
      <div>
        {constancia?.personaReturn?.datosMonotributo && (
          <div>
            <div className="title px-5 px-sm-0 pb-3 pt-5">
              DATOS DE ACTIVIDAD
            </div>
            <div className="mx-5 px-4 pxx-5 pt-3 card-body bg-dark text-light rounded-4">
              <div>
                <div>
                  Actividad:
                  {` ${constancia.personaReturn.datosMonotributo?.actividadMonotributista.descripcionActividad} `}
                </div>
              </div>
              <div className="d-none d-sm-block"></div>
              <div>
                <div className="mb-3">
                  Fecha de alta de actividad:
                  {`  ${formatDate(
                    constancia.personaReturn.datosMonotributo
                      ?.actividadMonotributista.periodo
                  )} `}
                </div>
              </div>
            </div>
          </div>
        )}
        {!constancia.personaReturn && <div></div>}
      </div>
    </div>
  );
};
