import styled from "styled-components";

export const Container = styled.div`
  .contenedor-centrado {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  button {
    padding: 10px;
    margin: 5px;
    font-size: 16px;
    cursor: pointer;
    border: none;
    border-radius: 70px;
    outline: none;
    padding-left: 70px;
    padding-right: 70px;
    transition: background-color 0.3s;
    background-color: #2e363a;
    color: white;
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: center;

    &:hover {
      background-color: #dddddd;
      color: #2e363a;
    }
  }

  button.active {
    background-color: #007bff;
    color: white;
  }

  .texto {
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: center;
  }
`;
