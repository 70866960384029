import React, { useCallback } from "react";
import { useConstancia } from "../../../../../../context/ConstanciaContext";

export const NotFound = () => {
  const { constancia } = useConstancia();

  const handleClickRegister = useCallback(async () => {
    try {
      window.open("https://app.tributosimple.com/register");
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <>
      {constancia.personaReturn?.errorConstancia && (
        <div className="card-body p-5 rounded-4">
          <p className="text-center pt-5">
            No se encontró información del Monotributo especificada dado que no
            se encuentra adherid@ al régimen simplificado actualmente
          </p>
          <div className="d-flex justify-content-center mt-5">
            <button className="button" onClick={handleClickRegister}>
              DATE DE ALTA EN EL MONOTRIBUTO
            </button>
          </div>
        </div>
      )}
      {!constancia.personaReturn && <div></div>}
    </>
  );
};
