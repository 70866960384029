import { Navigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { FC } from "react";

interface UnauthenticatedRouteProps {
  component: React.FC;
}

const UnauthenticatedRoute: FC<UnauthenticatedRouteProps> = ({
  component: Component,
}) => {
  const { user } = useAuth();

  return user ? <Navigate to="/consultas" /> : <Component />;
};

export default UnauthenticatedRoute;
