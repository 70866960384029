import { useCallback, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import Swal from "sweetalert2";
import { Modal } from "react-bootstrap";
import * as Yup from "yup";

import { useConsultations } from "../../context/ConsultationsContext";
import { useAuth } from "../../context/AuthContext";
import image1 from "../../assets/image/image1.svg";
import image2 from "../../assets/image/image2.svg";
import image3 from "../../assets/image/image3.svg";
import { Container, Container2 } from "./styles";
import fondo from "../../assets/image/fondo.svg";
import Input from "../../components/Input";
import { api } from "../../services/api";
import logo from "../../assets/logo.svg";

// ----------------------------------------------------------------------------
//                                  INTERFACES
// ----------------------------------------------------------------------------

interface ILogin {
  id?: number;
  name?: string;
  email: string;
  password: string;
  password_confirmation?: string;
}

interface IRegister {
  id?: number;
  name?: string;
  email: string;
  password: string;
  password_confirmation: string;
}

interface Errors {
  [key: string]: string;
}

export const Login = () => {
  const navigate = useNavigate();
  const formRef = useRef<FormHandles>(null);
  const [activeTab, setActiveTab] = useState("login");
  const { loadConsultations } = useConsultations();

  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
  };
  const { login } = useAuth();

  const handleSubmitLogin = useCallback(
    async (data: ILogin) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          email: Yup.string().required("Por favor escriba el correo"),
          password: Yup.string().required("Por favor escriba la contraseña"),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const { email, password } = {
          email: data.email,
          password: data.password,
        };

        await login({
          email: email,
          password: password,
        });
        loadConsultations();
        navigate("/consultas");
      } catch (err) {
        console.log(err);
        Swal.fire({
          title: "Error",
          text: "Los datos son incorrectos, intente nuevamente",
          icon: "error",
        });
      }
    },
    [login, navigate]
  );

  const handleSubmitRegister = useCallback(
    async (data: IRegister) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().required("Por favor escriba su nombre completo"),
          email: Yup.string().email().required("Por favor escriba el correo"),
          password: Yup.string().required("Por favor escriba la contraseña"),
          password_confirmation: Yup.string().required(
            "Debe coincidir con la contraseña"
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const { name, email, password, password_confirmation } = {
          name: data.name,
          email: data.email,
          password: data.password,
          password_confirmation: data.password_confirmation,
        };

        const response: any = await api.post(`auth/register`, {
          name,
          email,
          password,
          password_confirmation,
        });

        Swal.fire({
          title: "Bienvenido",
          text: "Usted se ha registrado exitosamente " + response.data.message,
          icon: "success",
        });
        login({ email: email, password: password });
        navigate("/");
      } catch (err: any) {
        const validationErrors: Errors = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach((error: any) => {
            validationErrors[error.path as string] = error.message;
          });
          formRef.current?.setErrors(validationErrors);
        } else {
          console.log(err);
          Swal.fire({
            title: "Error",
            text:
              "Los datos son incorrectos, intente nuevamente (" +
              err.response.data.password[0] +
              ")",
            icon: "error",
          });
        }
      }
    },
    [login, navigate]
  );

  const [showModal, setShowModal] = useState(false);

  const handleButtonClickLogin = () => {
    setShowModal(true);
    setActiveTab("login");
  };

  const handleButtonClickRegister = () => {
    setShowModal(true);
    setActiveTab("register");
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const modalStyle = {
    padding: "100px",
    margin: "auto",
    height: "auto",
    // Agrega otros estilos según sea necesario
  };

  const backgroundStyle = {
    backgroundImage: `url(${fondo})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    // Agrega otros estilos según sea necesario
  };

  const backgroundStyle2 = {
    backgroundImage: `url(${image3})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "50vh",
  };

  return (
    <>
      <Container2>
        <div className="pt-5 pb-3">
          <img src={logo} alt="logo" className="centrar-logo" />
        </div>
        <div>
          <div style={backgroundStyle}>
            <div className="container">
              <div className="row pt-5">
                <div className="col-sm">
                  <div className="text-title p-5 ps-4 pb-0">
                    Ahora podés conocer la situación tributaria de tus clientes,
                    proveedores y/o colaboradores.
                  </div>
                  <div className="p-5 ps-4 pt-3 pb-0">
                    <button className="button" onClick={handleButtonClickLogin}>
                      Iniciar sesión
                    </button>
                  </div>
                  <div className="p-5 ps-4 pt-0">
                    <div className="d-flex align-items-center">
                      <span>¿No tenés usuario?</span>
                      <p
                        onClick={handleButtonClickRegister}
                        className="text2 pt-3 ps-1 ml-2"
                      >
                        Registrate gratis
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-sm">
                  <img src={image1} alt="image1" />
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />
          <div className="container">
            <div className="row">
              <div className="col-sm">
                <div className="text-title2 p-5 ps-4 pb-3">Información:</div>
                <div className="p-5 pt-0">
                  <div className="text3">
                    <div className="item-container">
                      <div className="circle"></div>
                      <div>Nombre y Apellido</div>
                    </div>
                    <div className="item-container">
                      <div className="circle"></div>
                      <div>
                        Domicilio fiscal (Calle, número, provincia y CP)
                      </div>
                    </div>
                    <div className="item-container">
                      <div className="circle"></div>
                      <div>Actividad</div>
                    </div>
                    <div className="item-container">
                      <div className="circle"></div>
                      <div>Inscripción en monotributo (Sí/No)</div>
                    </div>
                    <div className="item-container">
                      <div className="circle"></div>
                      <div>Fecha de alta en monotributo</div>
                    </div>
                    <div className="item-container">
                      <div className="circle"></div>
                      <div>Categoría de monotributo</div>
                    </div>
                    <div className="item-container">
                      <div className="circle"></div>
                      <div>Fecha de alta de la actividad</div>
                    </div>
                    <div className="item-container">
                      <div className="circle"></div>
                      <div>Límite máximo de facturación de la categoría</div>
                    </div>
                    <div className="item-container">
                      <div className="circle"></div>
                      <div>
                        Valor de cuota del monotributo{" "}
                        <div> (Impuestos, obra social y jubilación)</div>
                      </div>
                    </div>
                    <div className="item-container">
                      <div className="circle"></div>
                      <div>Próximo vencimiento de impuestos (Monotributo)</div>
                    </div>
                    <div className="item-container">
                      <div className="circle"></div>
                      <div>¡Y mucho mas!</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm">
                <img src={image2} alt="image2" />
              </div>
            </div>
            <br />
            <br />
            <br />
          </div>
          <div style={backgroundStyle2}>
            <div className="container">
              <div className="pt-5 pb-3">
                <div className="text-title text-white centrar-logo">
                  Utilizá la plataforma:
                </div>
              </div>

              <div className="row ">
                <div className="col-sm login-card2 text-center">
                  <div className="text-center centrar-logo">
                    <button className="button2 cursornone">GRATIS</button>
                  </div>
                  <div className="pt-1"></div>
                  <div className="text-title3 text-black text-center pt-4">
                    10
                  </div>
                  <div className="p-5 ps-4 pt-0">
                    <div className="text-center text-black">
                      consultas mensuales
                    </div>
                  </div>
                  <div className="p-5 ps-4 pt-3 pb-0">
                    <button
                      className="button"
                      onClick={handleButtonClickRegister}
                    >
                      CREAR CUENTA
                    </button>
                  </div>
                </div>
                <div className="col-sm login-card2 text-center">
                  <div className="text-center centrar-logo">
                    <button className="button2 cursornone">SUSCRIPTOR</button>
                  </div>
                  <div className="">
                    <div className="text-center  text-consultas">
                      CONSULTAS ILIMITADAS
                    </div>
                  </div>
                  <div
                    className="text-black"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "70px",
                        verticalAlign: "top",
                        marginRight: "5px",
                        marginBottom: "35px",
                      }}
                    >
                      $
                    </div>
                    <div
                      className="text-title3 text-black text-center"
                      style={{ fontSize: "150px", verticalAlign: "middle" }}
                    >
                      99
                    </div>
                    <div
                      style={{
                        fontSize: "24px",
                        verticalAlign: "bottom",
                        marginLeft: "5px",
                        marginTop: "75px",
                      }}
                    >
                      +IVA
                    </div>
                  </div>
                  <div className="p-5 ps-4 pt-0">
                    <div className=" text-center text-black">x consulta</div>
                  </div>
                  <div className="p-5 ps-4 pt-3 pb-0">
                    <button
                      className="button"
                      onClick={handleButtonClickRegister}
                    >
                      CONTRATAR
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="centrar-logo">
              <div>
                <div className="d-flex align-items-center">
                  <span>¿Ya tenés cuenta?</span>
                  <p
                    onClick={handleButtonClickLogin}
                    className="text20 pt-3 ps-1 ml-2"
                  >
                    Iniciá sesión
                  </p>
                </div>
              </div>
            </div>
          </div>

          <Modal show={showModal} onHide={handleCloseModal} style={modalStyle}>
            <Modal.Body>
              <Container>
                <Form
                  ref={formRef}
                  onSubmit={
                    activeTab === "login"
                      ? handleSubmitLogin
                      : handleSubmitRegister
                  }
                  placeholder={undefined}
                >
                  <div className="login-card">
                    <div className="tabs-bar row letra">
                      <div className="col">
                        <div
                          className={`tab ${
                            activeTab === "login" ? "active-tab" : ""
                          }`}
                          onClick={() => handleTabChange("login")}
                        >
                          Iniciar Sesión
                          <div
                            className="tab-indicator"
                            style={{
                              width: activeTab === "login" ? "85px" : "85px",
                              backgroundColor:
                                activeTab === "login" ? "#0055BA" : "#fff",
                            }}
                          ></div>
                        </div>
                      </div>
                      <div className="col">
                        <div
                          className={`tab ${
                            activeTab === "register" ? "active-tab" : ""
                          }`}
                          onClick={() => handleTabChange("register")}
                        >
                          Registrarse
                          <div
                            className="tab-indicator"
                            style={{
                              width: activeTab === "register" ? "85px" : "85px",
                              backgroundColor:
                                activeTab === "register" ? "#0055BA" : "#fff",
                            }}
                          ></div>
                        </div>
                      </div>

                      <div
                        className="tab-indicator"
                        style={{
                          width: activeTab === "login" ? "100%" : "100%",
                        }}
                      ></div>
                    </div>
                    <h2>
                      {activeTab === "login" ? (
                        <div>
                          <div className="form-group">
                            <Input
                              className="form-control"
                              type="text"
                              name="email"
                              placeholder="Nombre de usuario"
                            />
                          </div>
                          <div className="form-group">
                            <Input
                              className="form-control"
                              type="password"
                              name="password"
                              placeholder="Contraseña"
                            />
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div className="form-group mt-3">
                            <div className="letra-3">
                              {" "}
                              <label htmlFor="password">Nombre completo:</label>
                            </div>

                            <Input
                              className="form-control"
                              type="text"
                              name="name"
                              placeholder="Escriba su Nombre completo"
                            />
                          </div>
                          <div className="form-group">
                            <div className="letra-3">
                              {" "}
                              <label htmlFor="password">Email:</label>
                            </div>

                            <Input
                              className="form-control"
                              type="text"
                              name="email"
                              placeholder="Escriba su email"
                            />
                          </div>
                          <div className="form-group">
                            <div className="letra-3">
                              {" "}
                              <label htmlFor="password">Contraseña:</label>
                            </div>

                            <Input
                              className="form-control"
                              type="password"
                              name="password"
                              placeholder="Escriba su Contraseña"
                            />
                          </div>
                          <div className="form-group">
                            <div className="letra-3">
                              {" "}
                              <label htmlFor="password">
                                Confirme contraseña:
                              </label>
                            </div>

                            <Input
                              className="form-control"
                              type="password"
                              name="password_confirmation"
                              placeholder="Escriba su confirmar contraseña"
                            />
                          </div>
                        </div>
                      )}
                    </h2>
                    <br />
                    <br />
                    <div className="text-center">
                      <button className="btn btn-primary letra-2" type="submit">
                        {activeTab === "login"
                          ? "Iniciar Sesión"
                          : "Registrarse"}
                      </button>
                    </div>
                  </div>
                </Form>
              </Container>
            </Modal.Body>
          </Modal>
        </div>
      </Container2>
    </>
  );
};

export default Login;
