interface IModalityTitleProps {
  modality: number;
}

export const ModalityTitle: React.FC<IModalityTitleProps> = ({ modality }) => {
  return (
    <div>
      <div className="texto-presstaciones">
        {modality === 1 ? "Prestación de servicios" : "Venta de cosas muebles"}
      </div>
    </div>
  );
};
