export const useFormatDate = () => {
  function formatDate2(dateString: number | undefined): string {
    if (dateString === undefined) {
      return "Fecha no válida";
    }

    const year = Math.floor(dateString / 100);
    const month = dateString % 100;

    // Agregar ceros a la izquierda si el mes es menor a 10
    const formattedMonth = month < 10 ? `0${month}` : `${month}`;

    return `${year}/${formattedMonth}`;
  }

  function formatDate(dateString: number | undefined): string {
    if (dateString === undefined) {
      return "Fecha no válida";
    }

    const year = Math.floor(dateString / 100);
    const month = dateString % 100;

    return `${getMonthName(month)} ${year}`;
  }

  function formatDateWithDay(dateString: string): string {
    const dateObject = new Date(dateString);
    const formattedDate = dateObject.toLocaleDateString("es-ES", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    return formattedDate;
  }

  function getMonthName(month: number): string {
    const monthNames = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];

    const monthIndex = month - 1;

    return monthNames[monthIndex] || "Mes inválido";
  }

  return {
    formatDate,
    formatDate2,
    formatDateWithDay,
  };
};
