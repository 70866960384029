import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import Swal from "sweetalert2";
import { useRemoveStoragePrefix } from "../hooks/useRemoveStoragePrefix";
import { useConstancia } from "./ConstanciaContext";
import { useSex } from "./SexContext";

interface DocumentContextType {
  document: number;
  loadDocument(document: number): void;
  setDocument(document: number): void;
  clearDocument(): void;
}

const DocumentContext = createContext<DocumentContextType | undefined>(
  undefined
);

export function DocumentProvider({
  children,
}: Readonly<{ children: React.ReactNode }>) {
  const { clearConstancia } = useConstancia();
  const { clearSex } = useSex();
  const { removeLocalStorageItemsWithPrefix } = useRemoveStoragePrefix();

  const [document, setDocument] = useState<number>(() => {
    const documentData = localStorage.getItem("@TS_KyC:document");

    if (documentData) {
      return JSON.parse(documentData);
    }

    return {} as number;
  });

  const handleSetDocument = useCallback(async (documentData: number) => {
    localStorage.setItem("@TS_KyC:document", JSON.stringify(documentData));
    setDocument(documentData);
    clearConstancia();
    clearSex();
  }, []);

  const loadDocument = useCallback(
    async (document: number) => {
      try {
        if (document) {
          await handleSetDocument(document);
        } else {
          const emptyArray: number = {} as number;
          await handleSetDocument(emptyArray);
        }
      } catch (err) {
        console.log(err);
      }
    },
    [handleSetDocument]
  );

  const clearDocument = useCallback(async () => {
    try {
      removeLocalStorageItemsWithPrefix("@TS_KyC:sex");
      handleSetDocument({} as number);
    } catch (err) {
      console.log(err);
      Swal.fire({
        title: "Error",
        text: "No se pudo completar",
        icon: "error",
      });
    }
  }, [handleSetDocument, removeLocalStorageItemsWithPrefix]);

  const contextValue: DocumentContextType = useMemo(() => {
    return {
      document: document,
      loadDocument: loadDocument,
      setDocument: handleSetDocument,
      clearDocument: clearDocument,
    };
  }, [clearDocument, document, handleSetDocument, loadDocument]);

  return (
    <DocumentContext.Provider value={contextValue}>
      {children}
    </DocumentContext.Provider>
  );
}

export function useDocument() {
  const context = useContext(DocumentContext);
  if (!context) {
    throw new Error("useDocument must be used within an ConstanciaProvider");
  }
  return context;
}
