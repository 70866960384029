import { useCallback } from "react";
import { useConstancia } from "../../../../../../context/ConstanciaContext";

interface IFeeDetail {
  modality: number;
}

export const FeeDetail: React.FC<IFeeDetail> = ({ modality }) => {
  const { constancia } = useConstancia();
  const thingTax =
    constancia.categoriaMonotributoInformacion?.impuesto_cosa.toLocaleString();
  const serviceTax =
    constancia.categoriaMonotributoInformacion?.impuesto_servicio.toLocaleString();

  const handleClickRegister = useCallback(async () => {
    try {
      window.open("https://app.tributosimple.com/register");
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <>
      <div className="px-3">
        <div className="px-5 mt-4">Detalle de cuota</div>
      </div>
      <div className="mt-1 mx-5 px-4 pt-3 card-body bg-blanco rounded-4">
        <div>
          1. Impuesto integrado: $ {modality === 1 ? serviceTax : thingTax}
        </div>
        <div>
          2. Aportes a la jubilación: ${" "}
          {constancia.categoriaMonotributoInformacion?.aporte_sipa.toLocaleString()}
        </div>
        <div className="mb-3">
          3. Aportes de Obra Social: ${" "}
          {constancia.categoriaMonotributoInformacion?.aporte_os.toLocaleString()}
        </div>
      </div>
      <div className="d-flex justify-content-center mt-5">
        <button className="button" onClick={handleClickRegister}>
          CONSULTÁ TU DEUDA CON AFIP
        </button>
      </div>
      <br />
      <br />
      <br />
    </>
  );
};
