import { useAuth } from "../../../../context/AuthContext";
import { SelectDocumentType } from "../SelectDocumentType";
import logo from "../../../../assets/logo.svg";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Container } from "./styles";
import { useSex } from "../../../../context/SexContext";
import { useConstancia } from "../../../../context/ConstanciaContext";
import { useDocument } from "../../../../context/DocumentContext";
import { useSubscription } from "../../../../hooks/useSubscription";
import { Consultations } from "../Consultations";

export const TopText = () => {
  const navigate = useNavigate();
  const { user, logout } = useAuth();
  const { clearSex } = useSex();
  const { clearConstancia } = useConstancia();
  const { clearDocument } = useDocument();
  const { requestSubscription } = useSubscription();

  const handleNewRequest = useCallback(async () => {
    try {
      clearConstancia();
      clearDocument();
      clearSex();
    } catch (err) {
      console.log(err);
      Swal.fire({
        title: "Error",
        text: "No pudimos completar su pedido",
        icon: "error",
      });
    }
  }, [clearConstancia, clearDocument, clearSex]);

  const handleLogout = useCallback(async () => {
    try {
      logout();
    } catch (err) {
      console.log(err);
      Swal.fire({
        title: "Error",
        text: "Usted no se ha autenticado",
        icon: "error",
      });
      navigate("/login");
    }
  }, [logout, navigate]);

  return (
    <Container>
      <div className="px-5 pt-3">
        {user && (
          <>
            <div className="d-flex justify-content-between flex-column flex-sm-row">
              <img src={logo} alt="logo" className="centrar-logo" />

              <div className="botones mt-2 mt-sm-0">
                {user.subscription_id === 3 && (
                  <>
                    <button className="boton" onClick={requestSubscription}>
                      SUSCRIBIRME
                    </button>
                    <span className="separador d-none d-sm-inline-block">
                      |
                    </span>
                  </>
                )}
                {user.subscription_id === 2 && (
                  <>
                    <button className="boton-disabled" disabled>
                      SUSCRIPCIÓN PENDIENTE
                    </button>
                    <span className="separador d-none d-sm-inline-block">
                      |
                    </span>
                  </>
                )}
                {user.subscription_id === 1 && (
                  <>
                    <button className="boton-disabled" disabled>
                      ¡ERES SUSCRIPTOR!
                    </button>
                    <span className="separador d-none d-sm-inline-block">
                      |
                    </span>
                  </>
                )}
                <button className="boton" onClick={handleNewRequest}>
                  NUEVA CONSULTA
                </button>
                <span className="separador d-none d-sm-inline-block">|</span>
                <button onClick={handleLogout} className="boton">
                  SALIR
                </button>
              </div>
            </div>
            <div className="d-flex me-1 flex-row flex-row-reverse">
              <div className="border pe-3 ps-3 pt-1 mt-1 consultations">
                <Consultations />
              </div>
            </div>

            <p className="text-center pt-5">
              Hola {user.name}, acá podrás buscar la información de monotributo
              de una persona a partir de su CUIT o DNI.
            </p>
          </>
        )}

        <SelectDocumentType />
      </div>
    </Container>
  );
};
