import React from "react";
import { useConstancia } from "../../../../context/ConstanciaContext";
import { Banner } from "./styles";

export const ResponsableInscripto = () => {
  const { constancia } = useConstancia();
  return (
    <Banner>
      {!constancia.personaReturn?.datosMonotributo &&
        constancia.personaReturn?.datosRegimenGeneral &&
        constancia.personaReturn?.datosGenerales?.tipoPersona === "FISICA" && (
          <div className="d-flex justify-content-center mt-5">
            <div className="banner">
              {"Refiere a una persona adherida al Regimen General (Autónomos)".toUpperCase()}
            </div>
          </div>
        )}
    </Banner>
  );
};
