import Swal from "sweetalert2";
import { api } from "../services/api";
import { useCallback } from "react";
import IMUserResponse from "../models/User/UserResponse";
import { useAuth } from "../context/AuthContext";

export const useSubscription = () => {
  const { setUser } = useAuth();
  const requestSubscription = useCallback(async () => {
    try {
      const response = await api.put(`users/request-subscription`);
      const user: IMUserResponse = response.data.user;
      setUser(user);
      Swal.fire({
        title: `¡Muy bien!`,
        text: `${response.data.message}`,
        icon: "success",
      });
    } catch (err) {
      Swal.fire({
        title: "Error",
        text: "Tu solicitud no pudo ser enviada, prueba de nuevo después de un momento. Si el problema persiste no dudes en contactarnos",
        icon: "error",
      });
    }
  }, [setUser]);

  return {
    requestSubscription,
  };
};
