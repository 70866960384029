import { useConstancia } from "../../../../context/ConstanciaContext";
import { Registered } from "./components/Registered";
import { Category } from "./components/Category";
import { RegistrationDate } from "./components/RegistrationDate";
import { ModalityTitle } from "./components/ModalityTitle";
import { AnnualBillLimit } from "./components/AnnualBillLimit";
import { MonthlyBillAverage } from "./components/MonthlyBillAverage";
import { FeeValue } from "./components/FeeValue";
import { FeeDetail } from "./components/FeeDetail";
import { NotFound } from "./components/NotFound";
import { useCallback, useEffect, useState } from "react";
import { Container } from "./styles";

export const MonotributoData = () => {
  const { constancia } = useConstancia();
  const [modality, setModality] = useState<number>(1);

  const handleModality = useCallback(async () => {
    try {
      const description =
        constancia.personaReturn?.datosMonotributo?.categoriaMonotributo.descripcionCategoria.toLocaleLowerCase();

      if (description?.includes("servicio")) {
        setModality(1);
      } else if (description?.includes("ventas")) {
        setModality(2);
      } else {
        setModality(0);
      }
    } catch (err) {
      console.log(err);
    }
  }, [
    constancia.personaReturn?.datosMonotributo?.categoriaMonotributo
      .descripcionCategoria,
  ]);

  useEffect(() => {
    handleModality();
  }, [handleModality]);

  return (
    <div>
      {constancia?.personaReturn?.datosMonotributo && (
        <div>
          <div className="title px-5 px-sm-0 pb-3 pt-5">MONOTRIBUTO</div>
          <div className="mx-5 px-4 pxx-5 card-body rounded-4">
            <Registered />
            <Category />
            <RegistrationDate />
            <Container>
              <ModalityTitle modality={modality} />
            </Container>
          </div>
          <AnnualBillLimit />
          <MonthlyBillAverage />
          <FeeValue modality={modality} />
          <FeeDetail modality={modality} />
        </div>
      )}

      <NotFound />
    </div>
  );
};
