import React from "react";
import { useConstancia } from "../../../../context/ConstanciaContext";
import { Banner } from "./styles";

export const LegalEntity = () => {
  const { constancia } = useConstancia();
  return (
    <Banner>
      {!constancia.personaReturn?.datosMonotributo &&
        constancia.personaReturn?.datosRegimenGeneral &&
        constancia.personaReturn?.datosGenerales?.tipoPersona ===
          "JURIDICA" && (
          <div className="d-flex justify-content-center mt-5">
            <div className="banner">
              {"Refiere a una persona juridica (Empresa)".toUpperCase()}
            </div>
          </div>
        )}
    </Banner>
  );
};
