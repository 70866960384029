import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { api } from "../services/api";
import IMConstanciaResponse from "../models/Constancia/ConstanciaResponse";

interface ConstanciaDniContextType {
  constanciaDni: IMConstanciaResponse;
  loadConstanciaDni(dni: number, sex: number): void;
  setConstanciaDni(constancia: IMConstanciaResponse): void;
}

const ConstanciaDniContext = createContext<
  ConstanciaDniContextType | undefined
>(undefined);

export function ConstanciaDniProvider({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) {
  const [constanciaDni, setConstanciaDni] = useState<IMConstanciaResponse>(
    () => {
      const constanciaDniData = localStorage.getItem("@TS_AFIP_CI:constancia");

      if (constanciaDniData) {
        return JSON.parse(constanciaDniData);
      }

      return {} as IMConstanciaResponse[];
    }
  );

  const handleSetConstanciaDni = useCallback(
    async (constanciaDniData: IMConstanciaResponse) => {
      localStorage.setItem(
        "@TS_AFIP_CI:constancia",
        JSON.stringify(constanciaDniData)
      );
      setConstanciaDni(constanciaDniData);
    },
    []
  );

  const loadConstanciaDni = useCallback(
    async (dni?: number, sex?: number) => {
      try {
        if (dni) {
          const response: any = await api.get(
            `padron-ci/complete/dni/${dni}/${sex}`
          );
          const constanciaDniData: IMConstanciaResponse = response.data;
          if (constanciaDniData !== undefined) {
            handleSetConstanciaDni(constanciaDniData);
          } else {
            const emptyArray: IMConstanciaResponse = {} as IMConstanciaResponse;
            handleSetConstanciaDni(emptyArray);
          }
        } else {
          const emptyArray: IMConstanciaResponse = {} as IMConstanciaResponse;
          handleSetConstanciaDni(emptyArray);
        }
      } catch (err) {
        console.log(err);
      }
    },
    [handleSetConstanciaDni]
  );

  const contextValue: ConstanciaDniContextType = useMemo(() => {
    return {
      constanciaDni: constanciaDni,
      setConstanciaDni: handleSetConstanciaDni,
      loadConstanciaDni: loadConstanciaDni,
    };
  }, [constanciaDni, handleSetConstanciaDni, loadConstanciaDni]);

  return (
    <ConstanciaDniContext.Provider value={contextValue}>
      {children}
    </ConstanciaDniContext.Provider>
  );
}

export function useConstanciaDni() {
  const context = useContext(ConstanciaDniContext);
  if (!context) {
    throw new Error(
      "useConstanciaDni must be used within an ConstanciaProvider"
    );
  }
  return context;
}
