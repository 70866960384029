import React from "react";
import { useConstancia } from "../../../../context/ConstanciaContext";
import { Banner } from "./styles";

export const RequestAccountanceHelp = () => {
  const { constancia } = useConstancia();

  const emailAddress = "administracion@gestionyvalor.com";
  const subject = "Solicito ayuda KyC";
  const body =
    "¡Hola! necesito ayuda con mi administración, contabilidad e impuestos";

  const mailtoLink = `mailto:${emailAddress}?subject=${encodeURIComponent(
    subject
  )}&body=${encodeURIComponent(body)}`;
  return (
    <Banner>
      {!constancia.personaReturn?.datosMonotributo &&
        constancia.personaReturn?.datosRegimenGeneral && (
          <div className="d-flex justify-content-center mt-2 mb-2">
            <div className="banner">
              Si necesitas ayuda con tu administración, contabilidad e impuestos
              escribinos a{" "}
              <a href={mailtoLink} target="_blank" rel="noopener noreferrer">
                administracion@gestionyvalor.com
              </a>
            </div>
          </div>
        )}
    </Banner>
  );
};
