import { useConstancia } from "../../../../context/ConstanciaContext";

interface IPersonalData {
  notFound?: boolean;
}

export const PersonalData: React.FC<IPersonalData> = ({ notFound }) => {
  const { constancia } = useConstancia();

  return (
    <div>
      {constancia?.personaReturn !== undefined && (
        <div>
          <div className="title px-5 px-sm-0">DATOS PERSONALES</div>
          <div className="px-5 pxx-5 mt-2">
            {constancia.personaReturn?.datosGenerales?.tipoPersona ===
            "FISICA" ? (
              <div>
                <b className="px-3">Nombre: </b>
                <div className="border">
                  {notFound
                    ? constancia?.personaReturn?.errorConstancia?.nombre ??
                      "No se obtuvo información"
                    : constancia?.personaReturn?.datosGenerales?.nombre ??
                      "No se obtuvo información"}
                </div>
              </div>
            ) : (
              <div>
                <b className="px-3">Razón Social: </b>
                <div className="border">
                  {notFound
                    ? constancia?.personaReturn?.errorConstancia?.nombre ??
                      "No se obtuvo información"
                    : constancia?.personaReturn?.datosGenerales?.razonSocial ??
                      "No se obtuvo información"}
                </div>
              </div>
            )}
            <div>
              <b className="px-3">Apellido: </b>
              <div className="border">
                {notFound
                  ? constancia?.personaReturn?.errorConstancia?.apellido ??
                    "No se obtuvo información"
                  : constancia?.personaReturn?.datosGenerales?.apellido ??
                    "No se obtuvo información"}
              </div>
            </div>
            <div>
              <b className="px-3">CUIT: </b>
              <div className="border">
                {notFound
                  ? constancia?.personaReturn?.errorConstancia?.idPersona ??
                    "No se obtuvo información"
                  : constancia?.personaReturn?.datosGenerales?.idPersona ??
                    "No se obtuvo información"}
              </div>
            </div>
            <div>
              <b className="px-0 px-sm-3">Fecha de Nacimiento: </b>
              <div className="border">No se obtuvo información</div>
            </div>
          </div>
        </div>
      )}

      {!constancia.personaReturn && <div></div>}
    </div>
  );
};
