import React, { useCallback, useEffect, useRef, useState } from "react";
import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";
import * as Yup from "yup";
import { useConstancia } from "../../../../context/ConstanciaContext";
import Swal from "sweetalert2";
import Input from "../../../../components/Input";
import { Col } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import Radio from "../../../../components/RadioButton";
import { useSex } from "../../../../context/SexContext";
import { Container } from "./styles";
import { useConsultations } from "../../../../context/ConsultationsContext";

interface IDni {
  dni: string;
  sexValueChecked: number;
}

interface Errors {
  [key: string]: string;
}

export const InputDni = () => {
  const formRef = useRef<FormHandles>(null);
  const { loadConstancia } = useConstancia();
  const { loadConsultations } = useConsultations();
  const { sex, setSex } = useSex();
  const [sexValueChecked, setSexValueChecked] = useState<number>(1);

  useEffect(() => {
    setSexValueChecked(sex);
    setSex(sex ?? {});
  }, [setSex, sex]);

  const handleSelectSex = (event: React.ChangeEvent<HTMLInputElement>) => {
    const sexValue = event.target.value === "1" ? 1 : 2;
    setSexValueChecked(sexValue);
    setSex(sexValue);
  };

  const handleSubmit = useCallback(
    async (data: IDni) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          dni: Yup.string()
            .matches(/^\d{8}$/, "Debe contener exactamente 8 dígitos")
            .required("El dni es requerido"),
        });
        console.log(sex, "sex");

        await schema.validate(data, {
          abortEarly: false,
        });

        const { dni } = data;

        typeof sex === "number"
          ? loadConstancia(2, parseInt(dni), sex)
          : Swal.fire(
              "Atención",
              "Debes seleccionar Hombre o Mujer",
              "warning"
            );
        loadConsultations();
      } catch (err: any) {
        const validationErrors: Errors = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach((error: any) => {
            validationErrors[error.path as string] = error.message;
          });
          formRef.current?.setErrors(validationErrors);
        } else {
          console.log(err);
          Swal.fire({
            title: "Error",
            text:
              "Los datos son incorrectos, intente nuevamente (" +
              err.response.data.password[0] +
              ")",
            icon: "error",
          });
        }
      }
    },
    [loadConstancia, loadConsultations, sex]
  );
  return (
    <Container>
      <Form ref={formRef} onSubmit={handleSubmit} placeholder={undefined}>
        <div className="contenedor-centrado">
          <Col className="align-items-end">
            <Input
              type="text"
              name="dni"
              maxLength={8}
              placeholder="Escriba el dni"
              style={{
                borderRadius: "25px",
                border: "none",
                padding: "5px",
                paddingLeft: "15px",
                marginBottom: "5px",
                background: "#D9D9D9",
                color: "#7a7777",
              }}
            />
            <button
              style={{ background: "transparent", border: "none" }}
              type="submit"
            >
              <div
                style={{
                  display: "inline-block",
                  borderRadius: "50%", // Hace que el contorno sea redondo
                  background: "#D9D9D9", // Color gris
                  padding: "6px", // Ajusta el tamaño del círculo
                  paddingRight: "10px", // Ajusta el tamaño del círculo
                  paddingLeft: "10px", // Ajusta el tamaño del círculo
                }}
              >
                <div>
                  <FaSearch style={{ color: "#3F3F3F", fontSize: "20px" }} />
                </div>
              </div>
            </button>
          </Col>
          <Col className="mt-4">
            <Radio
              className="form-check-input"
              name="sexValueChecked"
              id="male"
              value="1"
              checked={sexValueChecked === 1}
              onChange={handleSelectSex}
              style={{
                backgroundColor: sexValueChecked === 1 ? "#5DADE2" : "#D9D9D9",
                borderColor: "transparent",
              }}
            />
            <label className="form-check-label css2" htmlFor="male">
              Hombre
            </label>
            <Radio
              className="form-check-input"
              name="sexValueChecked"
              id="female"
              value="2"
              checked={sexValueChecked === 2}
              onChange={handleSelectSex}
              style={{
                backgroundColor: sexValueChecked === 2 ? "#5DADE2" : "#D9D9D9",
                borderColor: "transparent",
              }}
            />
            <label className="form-check-label css2" htmlFor="female">
              Mujer
            </label>
          </Col>
        </div>
      </Form>
    </Container>
  );
};
