import React from "react";
import { useConstancia } from "../../../../context/ConstanciaContext";

export const TaxData = () => {
  const { constancia } = useConstancia();

  return (
    <div>
      {constancia?.personaReturn?.datosGenerales && (
        <div>
          <div className="title px-5 px-sm-0 pb-3 mt-4">DOMICILIO FISCAL</div>
          <div className="mx-5 px-4 pxx-5 pt-3 card-body bg-dark text-light rounded-4 pb-3">
            <div>
              <div>
                Dirección:
                {` ${constancia.personaReturn.datosGenerales.domicilioFiscal.direccion} `}
              </div>
            </div>
            <div></div>
            <div>
              <div>
                Provincia:
                {` ${constancia.personaReturn.datosGenerales.domicilioFiscal.descripcionProvincia} `}
              </div>
            </div>
            <div>
              <div>
                CP:
                {` ${constancia.personaReturn.datosGenerales.domicilioFiscal.codPostal} `}
              </div>
            </div>
            {/* <div>
                <b>Provincia: </b>
                {
                  constancia.personaReturn.datosGenerales.domicilioFiscal
                    .descripcionProvincia
                }
              </p> */}
          </div>
        </div>
      )}
      {!constancia.personaReturn && <div></div>}
    </div>
  );
};
