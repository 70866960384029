import React from "react";
import { Routes, Route } from "react-router-dom";
import { Login } from "../pages/Login";
import { Register } from "../pages/Register";
import { MainPage } from "../pages/MainPage";
import PrivateRoute from "../components/PrivateRoute";
import UnauthenticatedRoute from "../components/UnauthenticatedRoute";

const routes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<UnauthenticatedRoute component={Login} />} />
      <Route
        path="/login"
        element={<UnauthenticatedRoute component={Login} />}
      />
      <Route
        path="/registro"
        element={<UnauthenticatedRoute component={Register} />}
      />
      <Route
        path="/consultas"
        element={<PrivateRoute component={MainPage} />}
      />
    </Routes>
  );
};

export default routes;
