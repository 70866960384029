import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { api } from "../services/api";
import IMConstanciaResponse from "../models/Constancia/ConstanciaResponse";
import Swal from "sweetalert2";
import { useRemoveStoragePrefix } from "../hooks/useRemoveStoragePrefix";

interface ConstanciaContextType {
  constancia: IMConstanciaResponse;
  loadConstancia(documentType?: number, document?: number, sex?: number): void;
  setConstancia(constancia: IMConstanciaResponse): void;
  clearConstancia(): void;
}

const ConstanciaContext = createContext<ConstanciaContextType | undefined>(
  undefined
);

export function ConstanciaProvider({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) {
  const { removeLocalStorageItemsWithPrefix } = useRemoveStoragePrefix();

  const [constancia, setConstancia] = useState<IMConstanciaResponse>(() => {
    const constanciaData = localStorage.getItem("@TS_KyC:constancia");

    if (constanciaData) {
      return JSON.parse(constanciaData);
    }

    return {} as IMConstanciaResponse[];
  });

  const handleSetConstancia = useCallback(
    async (constanciaData: IMConstanciaResponse) => {
      localStorage.setItem(
        "@TS_KyC:constancia",
        JSON.stringify(constanciaData)
      );
      setConstancia(constanciaData);
    },
    []
  );

  const loadConstancia = useCallback(
    async (documentType?: number, document?: number, sex?: number) => {
      try {
        if (documentType === 2) {
          const response: any = await api.get(
            `datos-persona/complete/dni/${document}/${sex}`
          );
          const constanciaData: IMConstanciaResponse = response.data;
          if (constanciaData !== undefined) {
            handleSetConstancia(constanciaData);
          } else {
            const emptyArray: IMConstanciaResponse = {} as IMConstanciaResponse;
            handleSetConstancia(emptyArray);
          }
        } else if (documentType === 1) {
          const response: any = await api.get(
            `datos-persona/complete/${document}`
          );
          const constanciaData: IMConstanciaResponse = response.data;
          if (constanciaData !== undefined) {
            handleSetConstancia(constanciaData);
          } else {
            const emptyArray: IMConstanciaResponse = {} as IMConstanciaResponse;
            handleSetConstancia(emptyArray);
          }
        } else {
          const emptyArray: IMConstanciaResponse = {} as IMConstanciaResponse;
          handleSetConstancia(emptyArray);
        }
      } catch (err) {
        console.log(err);
      }
    },
    [handleSetConstancia]
  );

  const clearConstancia = useCallback(async () => {
    try {
      removeLocalStorageItemsWithPrefix("@TS_KyC:constancia");
      handleSetConstancia({} as IMConstanciaResponse);
    } catch (err) {
      console.log(err);
      Swal.fire({
        title: "Error",
        text: "No se pudo completar",
        icon: "error",
      });
    }
  }, [handleSetConstancia, removeLocalStorageItemsWithPrefix]);

  const contextValue: ConstanciaContextType = useMemo(() => {
    return {
      constancia: constancia,
      setConstancia: handleSetConstancia,
      loadConstancia: loadConstancia,
      clearConstancia: clearConstancia,
    };
  }, [clearConstancia, constancia, handleSetConstancia, loadConstancia]);

  return (
    <ConstanciaContext.Provider value={contextValue}>
      {children}
    </ConstanciaContext.Provider>
  );
}

export function useConstancia() {
  const context = useContext(ConstanciaContext);
  if (!context) {
    throw new Error("useConstancia must be used within an ConstanciaProvider");
  }
  return context;
}
