import styled from "styled-components";

export const Container = styled.div`
  .monotributo-radio {
    margin-top: 10px; /* Espaciado superior */
  }

  .radio-group {
    display: flex;
    gap: 10px; /* Espaciado entre radios y etiquetas */
  }
`;
