import { useConsultations } from "../../../../context/ConsultationsContext";

export const Consultations = () => {
  const { consultations } = useConsultations();

  return (
    <h5 className="text-lg">
      {consultations && (
        <span className="text-lg">
          Llevas {consultations.this_month} consultas este mes
        </span>
      )}
    </h5>
  );
};
