import { useConstancia } from "../../../../context/ConstanciaContext";
import { useSubscription } from "../../../../hooks/useSubscription";

export const WarningSubscription = () => {
  const { constancia } = useConstancia();
  const { requestSubscription } = useSubscription();

  return (
    <>
      {constancia?.personaReturn?.warning && (
        <div className="card-body p-5 rounded-4">
          <p className="text-center pt-5">
            Has excedido el límite de consultas, para consultas ilimitadas,
            ¡Suscríbete!
          </p>
          <div className="d-flex justify-content-center mt-5">
            <button className="button" onClick={requestSubscription}>
              ¡SUSCRIBETE!
            </button>
          </div>
        </div>
      )}
    </>
  );
};
