import styled from "styled-components";

export const Container = styled.div`
.contenedor-centrado {
  display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.css {
 padding-left: 10px;
}
`;
