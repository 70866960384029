import { useConstancia } from "../../../../../../context/ConstanciaContext";
import { useFormatDate } from "../../../../../../hooks/useFormatDate";

interface IFeeValueProps {
  modality: number;
}

export const FeeValue: React.FC<IFeeValueProps> = ({ modality }) => {
  const { constancia } = useConstancia();
  const { formatDateWithDay } = useFormatDate();

  const serviceTotal =
    constancia.categoriaMonotributoInformacion?.total_servicio.toLocaleString();
  const thingTotal =
    constancia.categoriaMonotributoInformacion?.total_cosa.toLocaleString();
  return (
    <div className="px-5 pxx-5 mt-5 ">
      <div>
        <div className="px-3">Valor de Cuota:</div>
        <div className="border bg-azul  text-light p-3">
          <div className="numero">
            $ {modality === 1 ? serviceTotal : thingTotal}
          </div>
        </div>
      </div>
      <div>
        <div className="px-3">Próximo vencimiento:</div>
        <div className="border bg-azul  text-light p-3">
          <div className="numero">
            {constancia.proximoVencimiento?.date &&
              formatDateWithDay(constancia.proximoVencimiento.date)}
          </div>
        </div>
      </div>
    </div>
  );
};
