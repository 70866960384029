import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import Swal from "sweetalert2";
import { useRemoveStoragePrefix } from "../hooks/useRemoveStoragePrefix";
import IMConsultationResponse from "../models/Consultation/ConsultationResponse";
import { api } from "../services/api";

interface ConsultationsContextType {
  consultations: IMConsultationResponse;
  clearConsultations: () => void;
  loadConsultations(): void;
  setConsultations(consultations: IMConsultationResponse): void;
}

const ConsultationsContext = createContext<
  ConsultationsContextType | undefined
>(undefined);

export function ConsultationsProvider({
  children,
}: Readonly<{ children: React.ReactNode }>) {
  const { removeLocalStorageItemsWithPrefix } = useRemoveStoragePrefix();

  const [consultations, setConsultations] = useState<IMConsultationResponse>(
    () => {
      const consultationsData = localStorage.getItem("@TS_KyC:consultations");

      if (consultationsData) {
        return JSON.parse(consultationsData);
      }

      return {} as number;
    }
  );

  const handleSetConsultations = useCallback(
    async (consultationsData: IMConsultationResponse) => {
      localStorage.setItem(
        "@TS_KyC:consultations",
        JSON.stringify(consultationsData)
      );
      setConsultations(consultationsData);
    },
    []
  );

  const loadConsultations = useCallback(async () => {
    try {
      if (consultations) {
        const response = await api.get(`users/consultations/count`);
        const consultationsReportData: IMConsultationResponse = response.data;
        if (consultationsReportData !== undefined) {
          handleSetConsultations(consultationsReportData);
        } else {
          const emptyArray: IMConsultationResponse =
            {} as IMConsultationResponse;
          await handleSetConsultations(emptyArray);
        }
      } else {
        const emptyArray: IMConsultationResponse = {} as IMConsultationResponse;
        await handleSetConsultations(emptyArray);
      }
    } catch (err) {
      console.log(err);
    }
  }, [consultations, handleSetConsultations]);

  const clearConsultations = useCallback(async () => {
    try {
      removeLocalStorageItemsWithPrefix("@TS_KyC:consultations");
      handleSetConsultations({} as IMConsultationResponse);
    } catch (err) {
      console.log(err);
      Swal.fire({
        title: "Error",
        text: "No se pudo completar",
        icon: "error",
      });
    }
  }, [handleSetConsultations, removeLocalStorageItemsWithPrefix]);

  const contextValue: ConsultationsContextType = useMemo(() => {
    return {
      consultations: consultations,
      clearConsultations: clearConsultations,
      loadConsultations: loadConsultations,
      setConsultations: handleSetConsultations,
    };
  }, [
    consultations,
    clearConsultations,
    loadConsultations,
    handleSetConsultations,
  ]);

  return (
    <ConsultationsContext.Provider value={contextValue}>
      {children}
    </ConsultationsContext.Provider>
  );
}

export function useConsultations() {
  const context = useContext(ConsultationsContext);
  if (!context) {
    throw new Error(
      "useConsultations must be used within an ConsultationsProvider"
    );
  }
  return context;
}
