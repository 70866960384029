import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

import { AuthProvider } from "./context/AuthContext";
import LocalRoutes from "./routes";
import { ConstanciaProvider } from "./context/ConstanciaContext";
import { ConstanciaDniProvider } from "./context/ConstanciaDniContext";
import { DocumentProvider } from "./context/DocumentContext";
import { SexProvider } from "./context/SexContext";
import { ConsultationsProvider } from "./context/ConsultationsContext";

function App() {
  return (
    <BrowserRouter>
      <ConstanciaProvider>
        <ConsultationsProvider>
          <ConstanciaDniProvider>
            <SexProvider>
              <DocumentProvider>
                <AuthProvider>
                  <LocalRoutes />
                </AuthProvider>
              </DocumentProvider>
            </SexProvider>
          </ConstanciaDniProvider>
        </ConsultationsProvider>
      </ConstanciaProvider>
    </BrowserRouter>
  );
}

export default App;
