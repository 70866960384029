import { useConstancia } from "../../../../context/ConstanciaContext";

export const IdNotFound = () => {
  const { constancia } = useConstancia();

  const notFoundText = () => {
    let errorText = constancia.error;
    if (constancia.error === "No existe persona con ese Id") {
      errorText = "No existen datos para la búsqueda realizada";
    }
    return errorText;
  };

  return (
    <>
      {constancia?.error && (
        <div className="d-flex justify-content-center">
          <div className="banner-not-found">{notFoundText()}</div>
        </div>
      )}
    </>
  );
};
