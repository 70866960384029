import React, { useCallback, useRef } from "react";
import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";
import * as Yup from "yup";
import { useConstancia } from "../../../../context/ConstanciaContext";
import Swal from "sweetalert2";
import Input from "../../../../components/Input";
import { Col } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import { Container } from "./styles";
import { useConsultations } from "../../../../context/ConsultationsContext";

interface ICuit {
  cuit: number;
}

interface Errors {
  [key: string]: string;
}

export const InputCuit = () => {
  const formRef = useRef<FormHandles>(null);
  const { loadConstancia } = useConstancia();
  const { loadConsultations } = useConsultations();

  const handleSubmit = useCallback(
    async (data: ICuit) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          cuit: Yup.string()
            .matches(/^\d{11}$/, "Debe contener exactamente 11 dígitos") // Matches exactly 11 digits
            .required("El CUIT es requerido"),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const { cuit } = {
          cuit: data.cuit,
        };

        loadConstancia(1, cuit);
        loadConsultations();
      } catch (err: any) {
        const validationErrors: Errors = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach((error: any) => {
            validationErrors[error.path as string] = error.message;
          });
          formRef.current?.setErrors(validationErrors);
        } else {
          console.log(err);
          Swal.fire({
            title: "Error",
            text:
              "Los datos son incorrectos, intente nuevamente (" +
              err.response.data.password[0] +
              ")",
            icon: "error",
          });
        }
      }
    },
    [loadConstancia, loadConsultations]
  );
  return (
    <Container>
      <Form ref={formRef} onSubmit={handleSubmit} placeholder={undefined}>
        <div className="contenedor-centrado">
          <Col>
            <Input
              type="text"
              name="cuit"
              maxLength={11}
              placeholder="Escriba el cuit"
              style={{
                borderRadius: "25px",
                border: "none",
                padding: "5px",
                paddingLeft: "15px",
                marginBottom: "5px",
                background: "#D9D9D9",
                color: "#7a7777",
              }}
            />
            <button
              style={{ background: "transparent", border: "none" }}
              type="submit"
            >
              <div
                style={{
                  display: "inline-block",
                  borderRadius: "50%", // Hace que el contorno sea redondo
                  background: "#D9D9D9", // Color gris
                  padding: "6px", // Ajusta el tamaño del círculo
                  paddingRight: "10px", // Ajusta el tamaño del círculo
                  paddingLeft: "10px", // Ajusta el tamaño del círculo
                }}
              >
                <div>
                  <FaSearch style={{ color: "#3F3F3F", fontSize: "20px" }} />
                </div>
              </div>
            </button>
          </Col>
        </div>
      </Form>
    </Container>
  );
};
