import { useCallback, useEffect, useState } from "react";

import { useConstancia } from "../../../../context/ConstanciaContext";
import { WarningSubscription } from "../WarningSubscription";
import { MonotributoData } from "../MonotributoData";
import { ActivityData } from "../ActivityData";
import { PersonalData } from "../PersonalData";
import { Container } from "./styles";
import { TaxData } from "../TaxData";
import { ResponsableInscripto } from "../ResponsableInscripto";
import { LegalEntity } from "../LegalEntity";
import { RequestAccountanceHelp } from "../RequestAccountanceHelp";
import { IdNotFound } from "../IdNotFound";

export const GeneralInfo = () => {
  const { constancia } = useConstancia();
  const [notFound, setNotFound] = useState<boolean>();

  const handleSetNotFound = useCallback(() => {
    if (constancia.personaReturn?.errorConstancia) {
      setNotFound(true);
    } else {
      setNotFound(false);
    }
  }, [constancia.personaReturn?.errorConstancia]);

  useEffect(() => {
    handleSetNotFound();
  }, [handleSetNotFound]);

  return (
    <Container>
      <div className="container mb-2">
        <PersonalData notFound={notFound} />
      </div>
      <div className="container mb-2">
        <TaxData />
      </div>
      <ActivityData />
      <div className="container mb-2">
        <MonotributoData />
      </div>
      <WarningSubscription />
      <IdNotFound />
      <ResponsableInscripto />
      <LegalEntity />
      <RequestAccountanceHelp />
    </Container>
  );
};
