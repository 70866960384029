import styled from "styled-components";

export const Container = styled.div`
  .contenedor-centrado {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .imagen-centrada {
    margin-top: 20px; /* Puedes ajustar el margen según tus necesidades */
  }

  .botones {
    margin-top: 20px; /* Ajusta el margen según tu diseño */
  }

  .boton {
    font-size: 20px;
    border: none;
    background: none;
    cursor: pointer;
  }

  .boton-disabled {
    font-size: 20px;
    border: none;
    background: none;
    cursor: default;
  }

  .separador {
    margin: 0 auto; /* Ajusta el espacio entre el separador y los botones */
    font-size: 23px;
  }
  .consultations {
    background-color: #007bff;
    color: white;
  }
`;
