import styled from "styled-components";

export const Banner = styled.div`
  .banner {
    /* background-color: #2e363a; */
    /* color: white; */
    border: none;
    padding: 10px 20px;
    /* border-radius: 20px; */
    font-size: 15px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: center;
  }
`;
