import React, { useEffect, useRef, useState } from "react";
import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";
import { Col } from "react-bootstrap";
import { useDocument } from "../../../../context/DocumentContext";
import { Container } from "./styles";

export const SelectDocumentType = () => {
  const formRef = useRef<FormHandles>(null);
  const { setDocument } = useDocument();
  const [documentType, setDocumentType] = useState<number>(1);

  useEffect(() => {
    setDocumentType(1); // Establecer el tipo de documento predeterminado
  }, []);

  const handleSelectDocumentType = (type: number) => {
    setDocumentType(type);
    setDocument(type);
  };

  return (
    <Container>
      <Form
        ref={formRef}
        onSubmit={(data) => console.log(data)} // Puedes manejar la lógica de envío aquí
        placeholder={undefined}
      >
        <div className="mb-3 contenedor-centrado">
          <Col>
            <button
              className={documentType === 1 ? "active" : ""}
              onClick={() => handleSelectDocumentType(1)}
            >
              BUSCAR POR CUIT
            </button>
            <button
              className={documentType === 2 ? "active" : ""}
              onClick={() => handleSelectDocumentType(2)}
            >
              BUSCAR POR DNI
            </button>
          </Col>
        </div>
      </Form>
    </Container>
  );
};
