import { useConstancia } from "../../../../../../context/ConstanciaContext";

export const Category = () => {
  const { constancia } = useConstancia();

  return (
    <div className="d-flex justify-content-end">
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ margin: 0, marginRight: "10px" }}>
          Categoría monotributo:
        </div>
        <div className="border2">
          <div className="letra">
            {` ${constancia.categoriaMonotributoLetra} `}
          </div>
        </div>
      </div>
    </div>
  );
};
