import React from "react";
import { useConstancia } from "../../../../../../context/ConstanciaContext";

export const AnnualBillLimit = () => {
  const { constancia } = useConstancia();

  return (
    <div className="mx-5 mt-3 px-4 pxx-5 pt-3 pb-3 card-body bg-azul text-light rounded-4">
      <div>
        <div>
          Límite de Facturación Anual: ${" "}
          {constancia.categoriaMonotributoInformacion?.limite_superior.toLocaleString()}
        </div>
      </div>
    </div>
  );
};
