import React, { useState, useRef, useCallback } from "react";
import { useConstancia } from "../../../../../../context/ConstanciaContext";
import Radio from "../../../../../../components/RadioButton";
import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";
import { Container } from "./styles";

export const Registered = () => {
  const formRef = useRef<FormHandles>(null);
  const { constancia } = useConstancia();
  const [selectedValue, setSelectedValue] = useState(
    constancia.personaReturn?.datosMonotributo?.categoriaMonotributo ? "1" : "2"
  );
  const handleRadioChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSelectedValue(event.target.value);
  };
  const handleSubmit = useCallback(() => {
    console.log();
  }, []);
  return (
    <Container>
      <div className="monotributo-radio">
        Inscripción en monotributo:
        {constancia?.personaReturn?.datosMonotributo?.categoriaMonotributo ? (
          <Form ref={formRef} onSubmit={handleSubmit} placeholder={undefined}>
            <div className="radio-group pt-3">
              <Radio
                className="form-check-input"
                name="monotributo"
                id="radio1"
                value="1"
                checked={selectedValue === "1"}
                onChange={handleRadioChange}
                style={{
                  backgroundColor:
                    selectedValue === "1" ? "#5DADE2" : "#D9D9D9",
                  borderColor: "transparent",
                }}
              />
              <label htmlFor="radio1">Sí</label>
              <Radio
                className="form-check-input"
                name="monotributo"
                id="radio2"
                value="2"
                checked={selectedValue === "2"}
                onChange={handleRadioChange}
                style={{
                  backgroundColor:
                    selectedValue === "2" ? "#5DADE2" : "#D9D9D9",
                  borderColor: "transparent",
                }}
              />
              <label htmlFor="radio2">No</label>
            </div>
          </Form>
        ) : (
          <div>No disponible</div>
        )}
      </div>
    </Container>
  );
};
