import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import Swal from "sweetalert2";
import { useRemoveStoragePrefix } from "../hooks/useRemoveStoragePrefix";

interface SexContextType {
  sex: number;
  clearSex: () => void;
  loadSex(sex: number): void;
  setSex(sex: number): void;
}

const SexContext = createContext<SexContextType | undefined>(undefined);

export function SexProvider({
  children,
}: Readonly<{ children: React.ReactNode }>) {
  const { removeLocalStorageItemsWithPrefix } = useRemoveStoragePrefix();

  const [sex, setSex] = useState<number>(() => {
    const sexData = localStorage.getItem("@TS_KyC:sex");

    if (sexData) {
      return JSON.parse(sexData);
    }

    return {} as number;
  });

  const handleSetSex = useCallback(async (sexData: number) => {
    localStorage.setItem("@TS_KyC:sex", JSON.stringify(sexData));
    setSex(sexData);
  }, []);

  const loadSex = useCallback(
    async (sex: number) => {
      try {
        if (sex) {
          await handleSetSex(sex);
        } else {
          const emptyArray: number = {} as number;
          await handleSetSex(emptyArray);
        }
      } catch (err) {
        console.log(err);
      }
    },
    [handleSetSex]
  );

  const clearSex = useCallback(async () => {
    try {
      removeLocalStorageItemsWithPrefix("@TS_KyC:sex");
      handleSetSex({} as number);
    } catch (err) {
      console.log(err);
      Swal.fire({
        title: "Error",
        text: "No se pudo completar",
        icon: "error",
      });
    }
  }, [handleSetSex, removeLocalStorageItemsWithPrefix]);

  const contextValue: SexContextType = useMemo(() => {
    return {
      sex: sex,
      clearSex: clearSex,
      loadSex: loadSex,
      setSex: handleSetSex,
    };
  }, [sex, clearSex, loadSex, handleSetSex]);

  return (
    <SexContext.Provider value={contextValue}>{children}</SexContext.Provider>
  );
}

export function useSex() {
  const context = useContext(SexContext);
  if (!context) {
    throw new Error("useSex must be used within an SexProvider");
  }
  return context;
}
