import React from "react";
import { TopText } from "./components/TopText";
import { Col, Container, Row } from "react-bootstrap";
import { InputCuit } from "./components/InputCuit";
import { GeneralInfo } from "./components/GeneralInfo";
import { InputDni } from "./components/InputDni";
import { useDocument } from "../../context/DocumentContext";

export const MainPage = () => {
  const { document } = useDocument();
  return (
    <div>
      <TopText />
      <Container className="mb-4">
        <Row>{document === 2 ? <InputDni /> : <InputCuit />}</Row>
      </Container>
      <Row>
        <Col>
          <GeneralInfo />
        </Col>
      </Row>
    </div>
  );
};
