import { useCallback, useEffect, useState } from "react";
import { useConstancia } from "../../../../../../context/ConstanciaContext";

export const MonthlyBillAverage = () => {
  const { constancia } = useConstancia();
  const [monthlyAverage, setMonthlyAverage] = useState<number>();

  const handleSetMonthlyAverage = useCallback(async () => {
    const upperLimit =
      constancia?.categoriaMonotributoInformacion?.limite_superior;
    const dividedByTwelve = upperLimit && upperLimit / 12;
    setMonthlyAverage(dividedByTwelve);
  }, [constancia?.categoriaMonotributoInformacion?.limite_superior]);

  useEffect(() => {
    handleSetMonthlyAverage();
  }, [handleSetMonthlyAverage]);

  return (
    <div className="mt-3 mx-5 px-4 pxx-5 pb-3 pt-3 card-body bg-blanco rounded-4">
      <div>
        <div>
          Promedio Facturación Mensual: $ {monthlyAverage?.toLocaleString()}
        </div>
      </div>
    </div>
  );
};
