import styled from "styled-components";

export const Container = styled.div`
  .login-card {
    background: linear-gradient(
      #2e363a 0%,
      #0055ba 100%
    ); /* Gradiente lineal */
    border-radius: 25px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    color: #fff;
    padding: 30px;
    margin: -30px;
    min-width: 400px;
  }

  h2 {
    font-size: 1.8rem;
    text-align: center;
    margin-bottom: 20px;
  }

  .form-group {
    margin-top: 20px;
  }

  label {
    font-size: 1rem;
  }

  .btn-primary {
    background-color: #0ecfff;
    border-radius: 30px;
    border: none;
    padding: 10px;
    width: 100%;
    font-size: 1.2rem;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .btn-primary:hover {
    background-color: #2e363a;
  }

  .text-center {
    text-align: center;
  }

  .mt-3 {
    margin-top: 15px;
  }

  .tab-bar {
    display: flex;
    flex-direction: row; /* Alinea las pestañas horizontalmente */
    align-items: center;
    margin-bottom: 20px;
  }

  .tab-indicator {
    height: 4px;
    border-radius: 8px;
    transition: width 0.3s;
    margin: 0 auto;
  }

  .tab {
    cursor: pointer;
    padding: 10px;
    font-size: 1.2rem;
    border-radius: 8px;
    transition: background-color 0.3s;
    margin-right: 10px;
    text-align: center;
  }

  /* .tab:hover {
    background-color: #2e363a;
  }

  .active-tab {
    background-color: #2e363a;
  } */

  .form-control {
    border-radius: 30px;
    color: #000; /* Color del texto dentro del input */
  }

  .form-control::placeholder {
    color: #aeaeae; /* Color del placeholder */
  }

  .letra {
    font-size: 24px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
  }

  .letra-2 {
    font-size: 30px;
    font-weight: 700;
    line-height: 42px;
    letter-spacing: 0em;
  }

  .letra-3 {
    padding-left: 14px;
    text-align: start;
  }

  .centrar-logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 0 auto;
    padding-bottom: 40px;
  }
`;

export const Container2 = styled.div`
  .centrar-logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 0 auto;
    padding-bottom: 40px;
  }

  .text-title {
    color: #0055ba;
    font-size: 36px;
    font-weight: 700;
    line-height: 42px;
    letter-spacing: 0em;
    text-align: left;
    text-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
  }

  .text-title2 {
    color: #0055ba;
    font-size: 36px;
    font-weight: 700;
    line-height: 42px;
    letter-spacing: 0em;
    text-align: left;
  }

  .button {
    background-color: #0055ba; /* Fondo celeste */
    color: white; /* Letra blanca */
    border: none;
    padding: 10px 20px; /* Ajusta el relleno según tus preferencias */
    border-radius: 80px; /* Hace que el botón sea redondo */
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
  }

  .text1 {
    color: #000000;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
  }

  .text2 {
    color: #0ab2e6;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    cursor: pointer;
    margin-top: 2px;
  }

  .text20 {
    color: #0ab2e6;
    font-size: 15px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    cursor: pointer;
    text-decoration: underline;
  }

  .text3 {
    font-family: Work Sans;
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: -0.04em;
    text-align: left;
    color: #3f3f3f;
  }

  .item-container {
    display: flex;
    align-items: start;
  }

  .circle {
    margin: 10px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: #3f3f3f;
    margin-right: 8px;
  }

  .login-card2 {
    background: white;
    border-radius: 25px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    color: #fff;
    padding: 30px;
    margin: 50px;
    border-bottom: 3px solid #000000;
    max-width: 512px;

  }

  .text-title3 {
    text-align: center;
    font-size: 150px;
    font-weight: 700;
    line-height: 176px;
    letter-spacing: 0em;
    text-align: left;
  }

  .button2 {
    background: linear-gradient(
      #0ab2e6 0%,
      #0ecfff 100%
    ); /* Gradiente lineal */
    color: white; /* Letra blanca */
    border: none;
    padding: 10px 20px; /* Ajusta el relleno según tus preferencias */
    border-radius: 80px; /* Hace que el botón sea redondo */
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0em;
    margin-top: -55px;
    border-bottom: 3px solid #000000;
  }

  .text-consultas {
    font-size: 30px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: -0.04em;
    color: #3f3f3f;
  }

  .cursornone {
    cursor: default;
  }

`;
